import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { BiTrash } from "react-icons/bi";
import { BsTrashFill } from "react-icons/bs"
import { HiOutlinePlusSm } from "react-icons/hi";
import {
  getPastEntries,
  getListClass,
  getListStudent,
  getListReason,
  getSettingBySlug,
  deletePastEntry,
  exportPastEntries
} from "../Api/api";
import * as moment from "moment";
import { RiFileExcel2Fill } from "react-icons/ri";
import EditEntries from "./EditEntries";
import { ButtonGroup, Button } from "@mui/material"
import DeleteEntries from "./DeleteEntries";
import ExportEntries from "./ExportEntries"
import ConfirmDialog from "../Layout/ConfirmDialog";

const PastEntries = () => {
  const [listData, setListData] = useState([]);
  const [listClass, setListClass] = useState([]);
  const [listStudent, setListStudent] = useState([]);
  const [listReason, setListReason] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [openExport, setOpenExort] = useState(false);
  const [visible, setVisible] = useState(false);
  const [item, setItem] = useState(null);
  const [page, setPage] = useState(1)
  const [hasNext, setHasNext] = useState(false)
  const [search, setSearch] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [terms, setTerms] = useState([])

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const toggleDrawerExport = () => {
    setOpenExort((prevState) => !prevState);
  };

  const show = () => {
    setVisible(true);
  };
  const onReload = () => {
    setPage(1)
    getData(1);
  };

  const hide = () => {
    setVisible(false);
  };

  const deletAll = ()=>{
    deletePastEntry({id: "all"}).then(res=>{
      onReload()
    })
  }

  const exportData = async () =>{
    const data = await exportPastEntries()
    var a = document.createElement("a");
    a.href = window.URL.createObjectURL(data);
    a.download = "export-past-entry-"+moment().format("HH-mm-ss-DD-MM-YYYY")+".xlsx";
    a.click();
    a.remove()
  }


  const getData = (pageCurrent,keyword) => {
    getPastEntries(pageCurrent,keyword)
      .then((res) => {
        if (res?.results?.datas) {
          setListData(res.results.datas);
        }
        setHasNext(res.results.hasNext)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchSubmit = () =>{
    setPage(1)
    getData(1,search)
  }

  useEffect(() => {
    getData();
    getSettingBySlug("terms")
    .then(res=>{
      if(res.results?.data){
        setTerms(res.results?.data)
      }
    })
    getListClass(1,500)
      .then((res) => {
        if (res?.results?.classes) {
          let newClass = [];
          res.results.classes.map((item) => {
            return newClass.push({
              id: item._id,
              class: item.name,
            });
          });
          setListClass(newClass);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getListStudent()
      .then((res) => {
        if (res?.results?.students) {
          let newStudent = [];
          res.results.students.map((item) => {
            return newStudent.push({
              id: item._id,
              fullName: item.fullName,
            });
          });
          setListStudent(newStudent);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getListReason()
      .then((res) => {
        if (res?.results?.students) {
          let newReason = [];
          res.results.students.map((item) => {
            return newReason.push({
              id: item._id,
              reason: item.name,
            });
          });
          setListReason(newReason);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const formatType = (type) => {
    let name_type = "";
    if (type === "late_coming") {
      name_type = "Late Entry";
    } else {
      name_type = "Early Dismissal";
    }
    return name_type;
  };

  return (
    <div className="admin__main__body">
      <div className="admin__main__body__title">
        <span>Past Entries</span>
      </div>
      <div className={classNames("admin__main__body__search", "past-entries")}>
        <div style={{paddingRight: "20px", display: "flex", "justifyContent": "center", "border": "none", "background": "#fff", width: "60%",}}>
          <input
            className="search"
            style={{"height": "50px", "width": "60%", "paddingLeft": "20px","marginRight": "20px"}}
            type="text"
            placeholder="Search by student"
            onChange={(e)=>setSearch(e.target.value)}
          />
          <div
            onClick={() => {
              searchSubmit()
            }}
            className="button"
            style={{"height": "50px", "padding": "0 10px", "borderRadius": "5px"}}
          >
            <span>Filter</span>
          </div>
        </div>
        <div
          onClick={() => {
            toggleDrawer();
            setItem(null);
          }}
          className="button"
        >
          <HiOutlinePlusSm className="icon" />
          <span>Add Entry</span>
        </div>
        <div className="button delete" onClick={()=> setConfirmOpen(true)} style={{"width": "10%","marginRight": "20px","marginLeft": "20px"}}>
          <BsTrashFill className="icon" />
          <span>Delete All</span>
        </div>
        <div className="button excel" onClick={() => exportData()}>
          <RiFileExcel2Fill className="icon" />
          <span>Export</span>
        </div>
        <ConfirmDialog
            title="Delete All?"
            confirmOpen={confirmOpen}
            setConfirmOpen={setConfirmOpen}
            onConfirm={deletAll}
          >
            Are you sure you want to delete all?
          </ConfirmDialog>
      </div>
      <div className="admin__main__body__table">
        <div className="table">
          <div className="table__header">
            <div className="table__header__item name">Name</div>
            <div className="table__header__item">Class</div>
            <div className="table__header__item">Year</div>
            <div className="table__header__item">Term</div>
            <div className="table__header__item">Reason</div>
            <div className="table__header__item">Other Reasons</div>
            <div className="table__header__item">Category</div>
            <div className="table__header__item flex-action">ACTIONS</div>
          </div>
          {listData.map((item, index) => (
            <div className="table__body">
              <div className="table__body__item name">{item?.student?.fullName}</div>
              <div className="table__body__item">{item?.class?.name}</div>
              <div className="table__body__item">
                {item?.occurAt ? moment(item.occurAt).format("HH:mm MM/DD/YYYY") : ""}
              </div>
              <div className="table__body__item">
                {item?.term ? item.term : ""}
              </div>
              <div className="table__body__item">
                {item?.reason?.name ? item.reason.name : "Others"}
              </div>
              <div className="table__body__item">
                {item?.other ? item.other : ""}
              </div>
              <div className="table__body__item">
                {item?.type ? formatType(item.type) : ""}
              </div>
              <div className="table__body__item flex-action">
              <BiTrash
                onClick={() => {
                  show();
                  setItem(item);
                }}
                className="delete"
              />
            </div>
            </div>
          ))}
        </div>
        <div className="table_header" style={{display: 'flex', justifyContent: "flex-end", marginTop: '20px'}}>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button disabled={page <= 1} onClick={()=>{ setPage(page -1); getData(page -1 ) }}>Pre</Button>
              <Button disabled={!hasNext} onClick={()=>{  setPage(page +1);  getData(page +1) }}>Next</Button>
            </ButtonGroup>
        </div>
      </div>
      <EditEntries
        isOpen={isOpen}
        toggleDrawer={toggleDrawer}
        item={item}
        classes={listClass}
        students={listStudent}
        reasons={listReason}
        onReload={getData}
      />
      <ExportEntries
        isOpen={openExport}
        toggleDrawer={toggleDrawerExport}
        classes={listClass}
        terms={terms}
      />
      <DeleteEntries
        hide={hide}
        visible={visible}
        item={item}
        onReload={onReload}
      />
    </div>
  );
};

export default PastEntries;
