import React, { useState } from "react";
import { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
// import component 👇
import Drawer from "react-modern-drawer";

import { Select, MenuItem } from "@mui/material"

//import styles 👇
import "react-modern-drawer/dist/index.css";

import { createReason, updateReason } from "../Api/api";

const EditReason = (props) => {
  const { isOpen, toggleDrawer, item, onReload } = props;
  const [reasonName, setReasonName] = useState("");
  const [typeReason, setTypeReason] = useState("late_coming")
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (item) {
      setReasonName(item?.name);
      setDescription(item?.description);
      setTypeReason(item.type)
    }
  }, [item]);

  const onSave = async () => {
    const params = {
      name: reasonName,
      description: description,
      type: typeReason
    };

    try {
      const response = await createReason(params);
      console.log("KIEM TRA RESPONSE", response);
      if (response.statusCode === 200) {
        toggleDrawer();
        onReload();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onUpdate = async () => {
    const params = {
      name: reasonName,
      description: description,
      type: typeReason,
      id: item._id,
    };

    try {
      const response = await updateReason(params);
      console.log("KIEM TRA RESPONSE", response);
      if (response.statusCode === 200) {
        toggleDrawer();
        onReload();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Drawer
      open={isOpen}
      onClose={toggleDrawer}
      direction="right"
      className="drawer"
      size={700}
    >
      <div className="drawer__header">
        <div>
          <div className="drawer__header__title">Update Reason</div>
          <div className="drawer__header__subtitle">
            Updated your student reason and necessary information from here
          </div>
        </div>
        <div className="drawer__header__close" onClick={toggleDrawer}>
          <IoMdClose className="icon" />
        </div>
      </div>
      <div className="drawer__body">
        <div className="card-sys">
          <div className="card-sys__item">
            <div className="title">Reason</div>
            <div className="content">
              <div className="input-container">
                <input
                  className="input"
                  type="text"
                  value={reasonName}
                  onChange={(e) => setReasonName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="card-sys__item">
            <div className="title">Type</div>
            <div className="content">
            <Select
              value={typeReason}
              label="Type"
              onChange={(e)=> { setTypeReason(e.target.value) }}
            >
              <MenuItem value={"late_coming"}>Late Coming</MenuItem>
              <MenuItem value={"early_dissmisal"}>Early Dissmisal</MenuItem>
            </Select>
            </div>
          </div>
          <div className="card-sys__item">
            <div className="title">Description</div>
            <div className="content">
              <div className="input-container">
                <input
                  className="input"
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="drawer__footer">
        <button className="btn btn-cancel" onClick={toggleDrawer}>
          Cancel
        </button>
        {item ? (
          <button
            onClick={() => {
              onUpdate();
            }}
            className="btn btn-update"
          >
            Update Reason
          </button>
        ) : (
          <button
            onClick={() => {
              onSave();
            }}
            className="btn btn-update"
          >
            Add Reason
          </button>
        )}
      </div>
    </Drawer>
  );
};

export default EditReason;
