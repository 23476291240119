import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import { HiOutlinePlusSm } from "react-icons/hi";
import { Snackbar,Alert,ButtonGroup, Button } from "@mui/material"
import DeleteStudent from "./DeleteStudent";
import EditStudent from "./EditStudent";
import ConfirmDialog from "../Layout/ConfirmDialog";
import { getListStudent, getListClass, importStudentToClass, deleteStudent } from "../../components/Api/api";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsTrashFill } from "react-icons/bs"

const Student = () => {
  const [students, setStudents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [item, setItem] = useState(null);
  const [openSnack, setOpenSnack] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [hasNext, setHasNext] = useState(false)

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const show = () => {
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };

  let searchHandle = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setSearch(lowerCase);
  };

  const deletAllStudent = ()=>{
    deleteStudent({id: "all"}).then(res=>{
      getStudent(1)
      setPage(1)
    })
  }

  function ListStudent(props) {
    const filteredData = students.filter((el) => {
      if (props.input === "") {
        return el;
      } else {
        return el.fullName.toLowerCase().includes(props.input); // search theo name
      }
    });

    const getNameClass = (item) => {
      let listClass = [];
      item.classes.map((i) => {
        listClass.push(i.name);
      });
      return listClass.toString();
    };

    return (
      <ul>
        {filteredData.map((item, index) => (
          <div className="table__body">
            <div className="table__body__item ">{item.fullName}</div>
            <div className="table__body__item ">{getNameClass(item)}</div>
            <div className="table__body__item flex-action">
              <BiEdit
                onClick={() => {
                  toggleDrawer();
                  setItem(item);
                }}
                className="edit"
              />
              <BiTrash
                onClick={() => {
                  show();
                  setItem(item);
                }}
                className="delete"
              />
            </div>
          </div>
        ))}
      </ul>
    );
  }

  const getStudent = (pageCurrent,classId,keyword) => {
    getListStudent(pageCurrent,classId,keyword)
      .then((res) => {
        console.log("res", res);
        if (res?.results?.students) {
          setStudents(res.results.students);
        }
        setHasNext(res.results.hasNext)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchSubmit = () =>{
    setPage(1)
    getStudent(1,null,search)
  }

  useEffect(() => {
    getStudent();
    getListClass(1,200)
      .then((res) => {
        if (res?.results?.classes) {
          let newClass = [];
          res.results.classes.map((item) => {
            newClass.push({ name: item.name, id: item._id });
          });
          setClasses(newClass);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onReload = () => {
    getStudent(page);
  };
  const openSelectFile = () =>{
    document.getElementById("uploadfile-excel").click()
  }

  const importFileStudent = async (e) =>{
      const data = new FormData()
      data.append("file",e.target.files[0])
      await importStudentToClass(data)
      setOpenSnack(true)
      document.getElementById("uploadfile-excel").value = null
      setPage(1)
      getStudent();
  }

  return (
    <div className="admin__main__body">
      <div className="admin__main__body__title">
        <span>Student</span>
      </div>
      <Snackbar open={openSnack} autoHideDuration={3000} anchorOrigin={{vertical: "top", horizontal: "center"}} onClose={() =>setOpenSnack(false)}>
        <Alert onClose={() =>setOpenSnack(false)} severity="success" sx={{ width: '100%' }}>
          Update successfully
        </Alert>
      </Snackbar>
      <input type="file" id="uploadfile-excel" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={importFileStudent} style={{display: "none"}} />
      <div className={classNames("admin__main__body__search", "students")}>
        <div style={{paddingRight: "20px", display: "flex", "justifyContent": "center", "border": "none", "background": "#fff", width: "60%",}}>
          <input
            className="search"
            style={{"height": "50px", "width": "60%", "paddingLeft": "20px","marginRight": "20px"}}
            type="text"
            placeholder="Search by student"
            onChange={(e)=>setSearch(e.target.value)}
          />
          <div
            onClick={() => {
              searchSubmit()
            }}
            className="button"
            style={{"height": "50px", "padding": "0 10px", "borderRadius": "5px"}}
          >
            <span>Filter</span>
          </div>
        </div>
        <div
          onClick={() => {
            toggleDrawer();
            setItem(null);
          }}
          className="button"
        >
          <HiOutlinePlusSm className="icon" />
          <span>Add Student</span>
        </div>
        <div className="button delete" onClick={()=> setConfirmOpen(true)} style={{"width": "10%","marginRight": "20px","marginLeft": "20px"}}>
          <BsTrashFill className="icon" />
          <span>Delete All</span>
        </div>
        <div className="button excel" onClick={openSelectFile}>
          <RiFileExcel2Fill className="icon" />
          <span>Import</span>
        </div>
        <ConfirmDialog
            title="Delete Student?"
            confirmOpen={confirmOpen}
            setConfirmOpen={setConfirmOpen}
            onConfirm={deletAllStudent}
          >
            Are you sure you want to delete all?
          </ConfirmDialog>
      </div>
      <div className="admin__main__body__table">
        <div className="table">
          <div className="table__header">
            <div className="table__header__item">Name</div>
            <div className="table__header__item">Class</div>
            <div className="table__header__item flex-action">ACTIONS</div>
          </div>
          <ListStudent input={""} />
          
        </div>
        <div className="table_header" style={{display: 'flex', justifyContent: "flex-end", marginTop: '20px'}}>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button disabled={page <= 1} onClick={()=>{ setPage(page -1); getStudent(page -1 ) }}>Pre</Button>
              <Button disabled={!hasNext} onClick={()=>{  setPage(page +1);  getStudent(page +1) }}>Next</Button>
            </ButtonGroup>
        </div>
      </div>
      <EditStudent
        isOpen={isOpen}
        toggleDrawer={toggleDrawer}
        item={item}
        classes={classes}
        onReload={onReload}
      />
      <DeleteStudent
        hide={hide}
        visible={visible}
        item={item}
        onReload={onReload}
      />
    </div>
  );
};

export default Student;
