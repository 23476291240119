let baseURL = "https://latecomming-api.techzeus.co/";

export const getCookieToken = localStorage.getItem("token");

export const login = (params) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + "authentication/login?type=admin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

// ================================ MANAGER STUDENT ==============================//
export const getListStudent = (page,classId,keyword) => {
  page = page || 1;
  let url = "admin/student?page=" + page
  if(classId) url += "&classId="+classId
  if(keyword){
    url += "&keyword="+keyword.toLocaleUpperCase()
  }
  return new Promise((resolve, reject) => {
    fetch(baseURL + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const createStudent = (params) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + "admin/student", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const updateStudent = (params) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + `admin/student/${params.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const deleteStudent = (params) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + `admin/student/${params.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

// ================================ MANAGER CLASS ==============================//
export const getListClass = (page,limit,sortName,keyword) => {
  page = page || 1;
  limit = limit || 10
  sortName = sortName || "asc"
  let url = "admin/class?page=" + page +"&limit="+limit+"&sortName="+sortName
  if(keyword){
    url += "&keyword="+keyword
  }
  return new Promise((resolve, reject) => {
    fetch(baseURL + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
      // body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const createClass = (params) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + "admin/class", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const updateClass = (params) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + `admin/class/update/${params.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const deleteClass = (params) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + `admin/class/remove/${params.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const deleteAllClass = () => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + `admin/class/remove-all`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

// ================================ MANAGER PAST ENTRIES ==============================//
export const getPastEntries = (page,keyword) => {
  page = page || 1;
  let url = "admin/info?page=" + page
  if(keyword){
    url += "&keyword="+keyword.toLocaleUpperCase()
  }
  return new Promise((resolve, reject) => {
    fetch(baseURL + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const createPastEntries = (params) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + "admin/info", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const deletePastEntry = (params) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + `admin/info/${params.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};


// ================================ MANAGER USER ==============================//
export const getListUser = () => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + "admin/user", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const createUser = (params) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + "admin/user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const updateUser = (params) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + `admin/user/${params.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const deleteUser = (params) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + `admin/user/${params.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

// ================================ MANAGER REASON ==============================//
export const getListReason = () => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + "admin/reason", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const createReason = (params) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + "admin/reason", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const updateReason = (params) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + `admin/reason/${params.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const deleteReason = (params) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + `admin/reason/${params.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
// ================================ MANAGER TERM ==============================//

export const getTerm = () => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + "admin/setting", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const getSettingBySlug = (slug) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + "admin/setting/"+slug, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const updateTerm = (params) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + `admin/setting/${params.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
      body: JSON.stringify(params.data),
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const deleteTerm = (params) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + `admin/setting/${params.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookieToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

// ================================ MANAGER OTHERS ==============================//

export const importStudentToClass = (body) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + `admin/file/import-student-class`, {
      method: "POST",
      body,
      headers: {
        Authorization: "Bearer " + getCookieToken,
      },
    })
      .then((response) => resolve(response))
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const importClass = (body) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + `admin/file/import-phone-class`, {
      method: "POST",
      body,
      headers: {
        Authorization: "Bearer " + getCookieToken,
      },
    })
      .then((response) => resolve(response))
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const exportPastEntries = () => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + `admin/file/export-excel`, {
      headers: {
        Authorization: "Bearer " + getCookieToken,
      },
    })
      .then((res) => {
        return res.blob();
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};



export const exportPastEntriesv2 = (body) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + `admin/file/export-excel`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: "Bearer " + getCookieToken,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.blob();
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};
