import React, { useState } from "react";
import { useEffect } from "react";
import { HiOutlineMinusSm, HiOutlinePlusSm } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
// import component 👇
import Drawer from "react-modern-drawer";

//import styles 👇
import "react-modern-drawer/dist/index.css";

import { createClass, updateClass } from "../Api/api";

const EditClass = (props) => {
  const { isOpen, toggleDrawer, item, onReload } = props;
  const [className, setClassName] = useState("");
  const [teacherPhones, setTeacherPhones] = useState([]);
  const [teacherPhone, setTeacherPhone] = useState("");
  const [students, setStudents] = useState([]);

  useEffect(() => {
    if (item) {
      setClassName(item?.name);
      setTeacherPhones(item?.teacher_mobiles);
      setStudents(item?.students);
    }
  }, [item]);

  const handleAddTeacherPhone = () => {
    const newTeacherPhones = [...teacherPhones];
    newTeacherPhones.push(teacherPhone);
    setTeacherPhones(newTeacherPhones);
    setTeacherPhone("");
  };

  const handleRemoveTeacherPhone = (index) => {
    const newAddresses = [...teacherPhones];
    newAddresses.splice(index, 1);
    setTeacherPhones(newAddresses);
  };

  const onSave = async () => {
    const params = {
      name: className,
      teacher_mobiles: teacherPhones,
      students: [],
    };

    try {
      const response = await createClass(params);
      console.log("KIEM TRA RESPONSE", response);
      if (response.statusCode === 200) {
        toggleDrawer();
        onReload();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onUpdate = async () => {
    const params = {
      name: className,
      teacher_mobiles: teacherPhones,
      students: students,
      id: item._id,
    };
    try {
      const response = await updateClass(params);
      console.log("KIEM TRA RESPONSE", response);
      if (response.statusCode === 200) {
        toggleDrawer();
        onReload();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Drawer
      open={isOpen}
      onClose={toggleDrawer}
      direction="right"
      className="drawer"
      size={700}
    >
      <div className="drawer__header">
        <div>
          <div className="drawer__header__title">Update Class</div>
          <div className="drawer__header__subtitle">
            Updated your Class and necessary information from here
          </div>
        </div>
        <div className="drawer__header__close" onClick={toggleDrawer}>
          <IoMdClose className="icon" />
        </div>
      </div>
      <div className="drawer__body">
        <div className="card-sys">
          <div className="card-sys__item">
            <div className="title">Class Name</div>
            <div className="content">
              <div className="input-container">
                <input
                  className="input"
                  type="text"
                  value={className}
                  onChange={(e) => setClassName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="card-sys__item">
            <div className="title">Teacher Phones</div>
            <div className="content">
              <div className="input-container">
                <input
                  className="input"
                  type="text"
                  value={teacherPhone}
                  onChange={(e) => setTeacherPhone(e.target.value)}
                />
                <div onClick={handleAddTeacherPhone} className="minus">
                  <HiOutlinePlusSm className="icon" />
                </div>
              </div>
            </div>
          </div>
          <div className="card-sys__item">
            <div className="title"></div>
            <div className="content">
              {teacherPhones.map((item, index) => (
                <div className="input-container">
                  <span className="list">{item}</span>
                  <div
                    onClick={() => handleRemoveTeacherPhone(index)}
                    className="minus"
                  >
                    <HiOutlineMinusSm className="icon" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="drawer__footer">
        <button className="btn btn-cancel" onClick={toggleDrawer}>
          Cancel
        </button>
        {item ? (
          <button
            onClick={() => {
              onUpdate();
            }}
            disabled={teacherPhones.length == 0}
            className={teacherPhones.length == 0 ? "btn btn-disabled" : "btn btn-update" }
          >
            Update Class
          </button>
        ) : (
          <button
            onClick={() => {
              onSave();
            }}
            disabled={teacherPhones.length == 0}
            className={teacherPhones.length == 0 ? "btn btn-disabled" : "btn btn-update" }
          >
            Add Class
          </button>
        )}
      </div>
    </Drawer>
  );
};

export default EditClass;
