import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
// import component 👇
import Drawer from "react-modern-drawer";

//import styles 👇
import "react-modern-drawer/dist/index.css";
import { createUser, updateUser } from "../Api/api";

const EditUser = (props) => {
  const { isOpen, toggleDrawer, item, onReload } = props;

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (item) {
      setIsAdmin(item.isAdmin);
    }
  }, [item]);

  const onSave = async () => {
    const params = {
      username: userName,
      password: password,
      isAdmin: isAdmin,
      fullName: "",
    };

    try {
      if (password !== confirmPassword) {
        alert("Password and confirm password must be the same");
        return;
      } else {
        const response = await createUser(params);
        console.log("KIEM TRA RESPONSE", response);
        if (response.statusCode === 200) {
          toggleDrawer();
          onReload();
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onUpdate = async () => {
    const params = {
      isAdmin: isAdmin,
      id: item._id,
    };

    try {
      const response = await updateUser(params);
      console.log("KIEM TRA RESPONSE", response);
      if (response.statusCode === 200) {
        toggleDrawer();
        onReload();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Drawer
      open={isOpen}
      onClose={toggleDrawer}
      direction="right"
      className="drawer"
      size={700}
    >
      <div className="drawer__header">
        <div>
          <div className="drawer__header__title">Update Student</div>
          <div className="drawer__header__subtitle">
            Updated your Student and necessary information from here
          </div>
        </div>
        <div className="drawer__header__close" onClick={toggleDrawer}>
          <IoMdClose className="icon" />
        </div>
      </div>
      <div className="drawer__body">
        <div className="card-sys">
          {!item && (
            <>
              <div className="card-sys__item">
                <div className="title">Username</div>
                <div className="content">
                  <div className="input-container">
                    <input
                      className="input"
                      type="text"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="card-sys__item">
                <div className="title">Password</div>
                <div className="content">
                  <div className="input-container">
                    <input
                      className="input"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="card-sys__item">
                <div className="title">Confirm password</div>
                <div className="content">
                  <div className="input-container">
                    <input
                      className="input"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="card-sys__item">
            <div className="title">Admin</div>
            <div className="content">
              <div className="checkbox">
                <input
                  className="input"
                  type="checkbox"
                  value={isAdmin}
                  onClick={() => setIsAdmin(!isAdmin)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="drawer__footer">
        <button className="btn btn-cancel" onClick={toggleDrawer}>
          Cancel
        </button>
        {item ? (
          <button
            onClick={() => {
              onUpdate();
            }}
            className="btn btn-update"
          >
            Update User
          </button>
        ) : (
          <button
            onClick={() => {
              onSave();
            }}
            className="btn btn-update"
          >
            Add User
          </button>
        )}
      </div>
    </Drawer>
  );
};

export default EditUser;
