import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import { HiOutlinePlusSm } from "react-icons/hi";
import { deleteAllClass, getListClass } from "../../components/Api/api";
import DeleteClass from "./DeleteClass";
import EditClass from "./EditClass";
import { RiFileExcel2Fill } from "react-icons/ri";
import { importClass } from "../Api/api"
import { Snackbar,Alert,ButtonGroup, Button,Select, MenuItem } from "@mui/material"
import { BsTrashFill } from "react-icons/bs"
import ConfirmDialog from "../Layout/ConfirmDialog";

const Class = () => {
  const [classes, setClasses] = useState([]);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [item, setItem] = useState(null);
  const [openSnack, setOpenSnack] = useState(false)
  const [page, setPage] = useState(1)
  const [hasNext, setHasNext] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [sortName, setSortName] = useState("asc")

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const show = () => {
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };

  let searchHandle = (e) => {
    var lowerCase = e.target.value;
    setSearch(lowerCase);
  };

  const deletAllStudent = ()=>{
    deleteAllClass().then(()=>{
      setPage(1)
      getClass(1)
    })
  }

  const searchSubmit = () =>{
    setPage(1)
    getClass(1,10,sortName,search)
  }

  const getClass = (pageCurrent,limit,sortName,search) => {
    getListClass(pageCurrent,limit,sortName,search)
      .then((res) => {
        console.log(res);
        if (res?.results?.classes) {
          setClasses(res.results.classes);
        }
        setHasNext(res.results.hasNext)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openSelectFile = () =>{
    document.getElementById("uploadfile-excel-1").click()
  }

  const importFileStudent = async (e) =>{
      const data = new FormData()
      data.append("file",e.target.files[0])
      await importClass(data)
      setOpenSnack(true)
      document.getElementById("uploadfile-excel-1").value = null
      getClass();
  }

  useEffect(() => {
    getClass();
  }, []);

  return (
    <div className="admin__main__body">
      <div className="admin__main__body__title">
        <span>Class</span>
      </div>
      <Snackbar open={openSnack} autoHideDuration={3000} anchorOrigin={{vertical: "top", horizontal: "center"}} onClose={() =>setOpenSnack(false)}>
        <Alert onClose={() =>setOpenSnack(false)} severity="success" sx={{ width: '100%' }}>
          Update successfully
        </Alert>
      </Snackbar>
      <div className={classNames("admin__main__body__search", "classes")}>
        <div style={{paddingRight: "20px", display: "flex", "justifyContent": "space-between", "border": "none", "background": "#fff", width: "60%",}}>
          <input
            className="search"
            style={{"height": "50px", "width": "60%", "paddingLeft": "20px"}}
            type="text"
            placeholder="Search by class"
            onChange={searchHandle}
          />
          <div style={{width: '10%'}}>
            <Select
                value={sortName}
                label="Sort By"
                onChange={(e)=>setSortName(e.target.value)}
              >
                <MenuItem value={"sort_by"}>Sort By</MenuItem>
                <MenuItem value={"asc"}>Ascending</MenuItem>
                <MenuItem value={"desc"}>Decrease</MenuItem>
              </Select>
          </div>
          <div
            onClick={() => {
              searchSubmit()
            }}
            className="button"
            style={{"height": "50px", "padding": "0 10px", "borderRadius": "5px"}}
          >
            <span>Filter</span>
          </div>
        </div>
        
        <div
          onClick={() => {
            toggleDrawer();
            setItem(null);
          }}
          className="button"
        >
          <HiOutlinePlusSm className="icon" />
          <span>Add Class</span>
        </div>
        <div className="button delete" onClick={()=> setConfirmOpen(true)} style={{"width": "10%","marginRight": "20px","marginLeft": "20px"}}>
          <BsTrashFill className="icon" />
          <span>Delete All</span>
        </div>
        <div className="button excel" onClick={openSelectFile}>
          <RiFileExcel2Fill className="icon" />
          <span>Import</span>
        </div>
        <ConfirmDialog
            title="Delete Student?"
            confirmOpen={confirmOpen}
            setConfirmOpen={setConfirmOpen}
            onConfirm={deletAllStudent}
          >
            Are you sure you want to delete all?
          </ConfirmDialog>
        <input type="file" id="uploadfile-excel-1" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={importFileStudent} style={{display: "none"}} />
      </div>
      <div className="admin__main__body__table">
        <div className="table">
          <div className="table__header">
            <div className="table__header__item">Class</div>
            <div className="table__header__item">Teacher Phone</div>
            <div className="table__header__item flex-action">Actions</div>
          </div>
          {classes.map((item, index) => (
            <div className="table__body">
              <div className="table__body__item">{item.name}</div>
              <div className="table__body__item">
                {item.teacher_mobiles.toString()}
              </div>
              <div className="table__body__item flex-action">
                <BiEdit
                  onClick={() => {
                    toggleDrawer();
                    setItem(item);
                  }}
                  className="edit"
                />
                <BiTrash
                  onClick={() => {
                    show();
                    setItem(item);
                  }}
                  className="delete"
                />
              </div>
            </div>
          ))}
        </div>
        <div className="table_header" style={{display: 'flex', justifyContent: "flex-end", marginTop: '20px'}}>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button disabled={page <= 1} onClick={()=>{ setPage(page -1); getClass(page -1 ) }}>Pre</Button>
              <Button disabled={!hasNext} onClick={()=>{  setPage(page +1);  getClass(page +1) }}>Next</Button>
            </ButtonGroup>
        </div>
      </div>
      <EditClass
        isOpen={isOpen}
        toggleDrawer={toggleDrawer}
        item={item}
        onReload={()=>getClass(page)}
      />
      <DeleteClass
        visible={visible}
        hide={hide}
        item={item}
        onReload={()=>getClass(page)}
      />
    </div>
  );
};

export default Class;
