import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import { HiOutlinePlusSm } from "react-icons/hi";
import { Classes } from "../Api/data";
import { getListUser } from "../../components/Api/api";
import EditUser from "./EditUser";
import DeleteUser from "./DeleteUser";

const Users = () => {
  const [listUser, setListUser] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [item, setItem] = useState(null);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const show = () => {
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };

  const getUser = () => {
    getListUser()
      .then((res) => {
        if (res?.results?.users) {
          setListUser(res.results.users);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="admin__main__body">
      <div className="admin__main__body__title">
        <span>Users</span>
      </div>
      <div className={classNames("admin__main__body__search", "classes")}>
        <input className="search" type="text" placeholder="Search by Users" />
        <div
          onClick={() => {
            toggleDrawer();
            setItem(null);
          }}
          className="button"
        >
          <HiOutlinePlusSm className="icon" />
          <span>Add User</span>
        </div>
      </div>
      <div className="admin__main__body__table">
        <div className="table">
          <div className="table__header">
            <div className="table__header__item flex-id">ID</div>
            <div className="table__header__item">User</div>
            <div className="table__header__item">Admin</div>
            <div className="table__header__item">Actions</div>
          </div>
          {listUser.map((item, index) => (
            <div className="table__body">
              <div className="table__body__item flex-id">{index + 1}</div>
              <div className="table__body__item">{item.username}</div>
              <div className="table__body__item">
                {item.isAdmin === true ? "Yes" : "No"}
              </div>
              <div className="table__body__item">
                <BiEdit
                  onClick={() => {
                    toggleDrawer();
                    setItem(item);
                  }}
                  className="edit"
                />
                {item.username != "admin" && <BiTrash
                  onClick={() => {
                    show();
                    setItem(item);
                  }}
                  className="delete"
                />}
              </div>
            </div>
          ))}
        </div>
      </div>
      <EditUser
        isOpen={isOpen}
        toggleDrawer={toggleDrawer}
        item={item}
        onReload={getUser}
      />

      <DeleteUser
        visible={visible}
        hide={hide}
        item={item}
        onReload={getUser}
      />
    </div>
  );
};

export default Users;
