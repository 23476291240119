import React from "react";
import { HiOutlineViewGrid, HiOutlineUserGroup } from "react-icons/hi";
import { RiDropboxLine, RiListUnordered, RiBallPenLine } from "react-icons/ri";
import { GiPokecog } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";
import { IoLogOutOutline } from "react-icons/io5";
import classNames from "classnames";

const _nav = [
  {
    name: "Past Entries",
    icon: <RiListUnordered className="icon" />,
  },
  {
    name: "Student",
    icon: <HiOutlineViewGrid className="icon" />,
  },
  {
    name: "Class",
    icon: <RiDropboxLine className="icon" />,
  },
  {
    name: "Reason",
    icon: <RiBallPenLine className="icon" />,
  },
  {
    name: "User",
    icon: <HiOutlineUserGroup className="icon" />,
  },
  {
    name: "Setting",
    icon: <GiPokecog className="icon" />,
  },
];

const Nav = ({ active, setActive }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
    window.location.reload();
  };

  return (
    <div className="admin__nav">
      <div className="admin__nav__header">
        <span>Student</span>
        <span>Management</span>
        {/* <span>System</span> */}
      </div>
      <div className="admin__nav__subnav">
        {_nav.map((item, index) => (
          <a
            key={index}
            onClick={() => setActive(item.name)}
            href={`#${item.name}`}
            className={classNames(
              "admin__nav__subnav__item",
              active === item.name && "active"
            )}
          >
            {item.icon}
            <span>{item.name}</span>
          </a>
        ))}
      </div>
      <div className="admin__nav__logout">
        <Link
          onClick={() => {
            handleLogout();
          }}
          className="btn-logout"
          to="/login"
        >
          <IoLogOutOutline className="icon" />
          <span>Log out</span>
        </Link>
      </div>
    </div>
  );
};

export default Nav;
