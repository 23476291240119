import React, { useState } from "react";
import { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
// import component 👇
import Drawer from "react-modern-drawer";

//import styles 👇
import "react-modern-drawer/dist/index.css";
import MultiSelect from "../../common/MultiSelect";

import { createStudent, updateStudent } from "../Api/api";

const EditStudent = (props) => {
  const { isOpen, toggleDrawer, item, classes, onReload } = props;
  const [option, setOption] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [studentName, setStudentName] = useState("");

  useEffect(() => {
    if (classes) {
      let newOptions = [];
      classes.map((item) => {
        return newOptions.push({
          value: item.id,
          label: item.name,
        });
      });
      setOption(newOptions);
    }
    if (item) {
      setStudentName(item?.fullName);
      let newOptions = [];
      item?.classes.map((item) => {
        return newOptions.push({
          value: item._id,
          label: item.name,
        });
      });
      setSelectedOption(newOptions);
    }
  }, [item]);

  const onSave = async () => {
    let arrayValue = [];
    selectedOption.map((item) => arrayValue.push(item.value));

    const params = {
      fullName: studentName,
      classes: arrayValue,
    };

    try {
      const response = await createStudent(params);
      if (response.statusCode === 200) {
        onReload();
        toggleDrawer();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onUpdate = async () => {
    let arrayValue = [];
    selectedOption.map((item) => arrayValue.push(item.value));

    const params = {
      fullName: studentName,
      classes: arrayValue,
      id: item._id,
    };

    try {
      const response = await updateStudent(params);
      if (response.statusCode === 200) {
        toggleDrawer();
        onReload();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Drawer
      open={isOpen}
      onClose={toggleDrawer}
      direction="right"
      className="drawer"
      size={700}
    >
      <div className="drawer__header">
        <div>
          <div className="drawer__header__title">Update Student</div>
          <div className="drawer__header__subtitle">
            Updated your Student and necessary information from here
          </div>
        </div>
        <div className="drawer__header__close" onClick={toggleDrawer}>
          <IoMdClose className="icon" />
        </div>
      </div>
      <div className="drawer__body">
        <div className="card-sys">
          <div className="card-sys__item">
            <div className="title">Student Name</div>
            <div className="content">
              <div className="input-container">
                <input
                  className="input"
                  type="text"
                  value={studentName}
                  onChange={(e) => setStudentName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="card-sys__item">
            <div className="title">Class</div>
            <div className="content">
              <div className="select">
                <MultiSelect
                  options={option}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="drawer__footer">
        <button className="btn btn-cancel" onClick={toggleDrawer}>
          Cancel
        </button>
        {item ? (
          <button
            onClick={() => {
              onUpdate();
            }}
            className="btn btn-update"
          >
            Update Student
          </button>
        ) : (
          <button
            onClick={() => {
              onSave();
            }}
            className="btn btn-update"
          >
            Add Student
          </button>
        )}
      </div>
    </Drawer>
  );
};

export default EditStudent;
