import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../components/Api/api";

const Login = () => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const navigate = useNavigate();

  const setCookieToken = (token) => {
    localStorage.setItem("token", token);
  };

  const handleLogin = () => {
    const params = {
      username,
      password,
    };
    login(params)
      .then((res) => {
        if (res?.results?.access_token) {
          setCookieToken(res.results.access_token);
          navigate("/");
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="login">
      <div className="login__card">
        <div className="login__header">
          <span className="text-header">Student</span>
          <span className="text-header">Management</span>
        </div>
        <div className="login__main__body__input">
          <input
            type="text"
            placeholder="Username"
            required
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            required
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="login__button__login">
          <div onClick={() => handleLogin()} className="btn-login">
            <span>Login</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
