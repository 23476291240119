import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import { HiOutlinePlusSm } from "react-icons/hi";

const InfoStudent = () => {
  const [detail, setDetail] = useState([]);

  return (
    <div className="admin__main__body">
      <div className="admin__main__body__title">
        <span>Infomation Student</span>
      </div>
      <div className="admin__main__body__table"></div>
    </div>
  );
};

export default InfoStudent;
