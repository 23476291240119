import React, { useState } from "react";
import { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
// import component 👇
import Drawer from "react-modern-drawer";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import moment from "moment";
import OutlinedInput from '@mui/material/OutlinedInput';
import {
  exportPastEntriesv2,
} from "../Api/api";

//import styles 👇
import "react-modern-drawer/dist/index.css";
const ExportEntries = (props) => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };
  const { isOpen, toggleDrawer, classes, terms} =
    props;
  const [startAt, setStartAt] = useState(null)
  const [endAt, setEndAt] = useState(null)
  const [classIds, setClassIds] = useState([])
  const [term, setTerm] = useState("")
  const handleChangeClass = (e) => {
    const {
      target: { value },
    } = e;
    setClassIds(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const exportData = async () =>{
    const body ={
      startAt,
      endAt,
      classes: classIds,
      terms: term
    }
    const data = await exportPastEntriesv2(body)
    var a = document.createElement("a");
    a.href = window.URL.createObjectURL(data);
    a.download = "export-past-entry-"+moment().format("HH-mm-ss-DD-MM-YYYY")+".xlsx";
    a.click();
    a.remove()
    setStartAt(null)
    setEndAt(null)
    setClassIds([])
    setTerm("")
    toggleDrawer()
  }

  useEffect(()=>{
    setStartAt(null)
    setEndAt(null)
    setClassIds([])
    setTerm("")
  },[isOpen])
  return (
    <Drawer
      open={isOpen}
      onClose={toggleDrawer}
      direction="right"
      className="drawer"
      size={700}
    >
      <div className="drawer__header">
        <div>
          <div className="drawer__header__title">Export Entries</div>
          <div className="drawer__header__subtitle">
            Filter data to export
          </div>
        </div>
        <div className="drawer__header__close" onClick={toggleDrawer}>
          <IoMdClose className="icon" />
        </div>
      </div>
      <div className="drawer__body">
        <div className="card-sys">
          <div className="card-sys__item">
            <FormControl sx={{ width: 300, marginBottom: 4 }}>
            <InputLabel id="class-multiple-name-label">Classes</InputLabel> 
            <Select
                labelId="class-multiple-checkbox-label"
                id="class-multiple-checkbox"
                multiple
                value={classIds}
                onChange={handleChangeClass}
                input={<OutlinedInput label="Class" />}
                MenuProps={MenuProps}
                >
                {classes.map((cls) => (
                    <MenuItem key={cls.id} value={cls.id}>
                      {cls.class}
                    </MenuItem>
                ))}
            </Select>
            </FormControl>
          </div>
          <div className="card-sys__item">
            <div className="content">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Start At"
                value={startAt}
                onChange={(newValue)=>setStartAt(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            </div>
          </div>
          <div className="card-sys__item">
            <div className="content">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="End At"
                value={endAt}
                onChange={(newValue)=>setEndAt(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            </div>
          </div>
          <div className="card-sys__item">
          <FormControl sx={{ width: 300, marginBottom: 4 }}>
            <InputLabel id="terms-multiple-name-label">Term</InputLabel> 
            <Select
                value={term}
                label="Term"
                onChange={(e)=>setTerm(e.target.value)}
              >
                { terms.map(t => <MenuItem value={t.name}>{t.name}</MenuItem>) }
            </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="drawer__footer">
        <button className="btn btn-cancel" onClick={toggleDrawer}>
          Cancel
        </button>
          <button
            onClick={() => {
              exportData()
            }}
            className="btn btn-update"
          >
            Export
          </button>
      </div>
    </Drawer>
  );
};

export default ExportEntries;
