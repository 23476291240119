import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { getTerm, updateTerm } from "../Api/api";
import AddSetting from "./AddSetting";
import { Snackbar, Alert } from "@mui/material";
import DeleteSetting from "./DeleteSetting";

const SettingScreen = () => {
  const [listData, setListData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [ID, setID] = useState("");
  const [visible, setVisible] = useState(false);

  const [passcode, setPasscode] = useState(null);
  const [passcodeNew, setPasscodeNew] = useState("");
  const [openSnack, setOpenSnack] = useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const show = () => {
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };

  const getData = () => {
    getTerm()
      .then((res) => {
        console.log("first", res);
        if (res?.results) {
          console.log("first 123");
          res.results.filter((item) => {
            return (
              item.slug === "terms" && setListData(item.data),
              item.slug === "terms" && setID(item._id)
            );
          });
          res.results.filter((item) => {
            return item.slug === "password-custom" && setPasscode(item);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateSetting = async (id) => {
    await updateTerm({
      id,
      data: {
        value: passcodeNew,
      },
    });
    setOpenSnack(true);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="admin__main__body">
      <div className="admin__main__body__title">
        <span>Setting</span>
      </div>
      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setOpenSnack(false)}
      >
        <Alert
          onClose={() => setOpenSnack(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Update successfully
        </Alert>
      </Snackbar>
      <div className={classNames("admin__main__body__setting", "setting")}>
        <div className="admin__main__body__title">
          <span>Set passcode for App: </span>
          <input
            className="search"
            type="text"
            placeholder="Passcode"
            onChange={(e) => {
              setPasscodeNew(e.target.value);
            }}
            defaultValue={passcode?.data?.value}
          />
          <div className="card-sys__button" style={{ marginTop: 0 }}>
            <button
              onClick={() => updateSetting(passcode._id)}
              className="btn-update"
            >
              Update
            </button>
          </div>
        </div>
      </div>

      <div className={classNames("admin__main__body__setting", "setting")}>
        <div className="admin__main__body__title">
          <span>Set term dates for App: </span>
        </div>
        {listData.length > 0 &&
          listData.map((item, index) => (
            <div className="admin__main__body__title">
              <input
                disabled
                type="text"
                className="search"
                value={item.name}
              />
              <span className="space"> : </span>
              <input
                disabled
                className="search"
                type="date"
                value={moment(item.from).format("YYYY-MM-DD")}
              />
              <span className="space"> - </span>
              <input
                disabled
                className="search"
                type="date"
                value={moment(item.to).format("YYYY-MM-DD")}
              />
            </div>
          ))}
      </div>
      {listData.length > 0 && (
        <div className="card-sys__button">
          <button
            onClick={() => {
              toggleDrawer();
            }}
            className="btn-update"
          >
            Edit Term
          </button>
          {/* <button
            onClick={() => {
              show();
            }}
            className="btn-delete"
          >
            Delete Term */}
          {/* </button> */}
        </div>
      )}
      <AddSetting
        isOpen={isOpen}
        toggleDrawer={toggleDrawer}
        onReload={getData}
        listData={listData}
        ID={ID}
      />

      <DeleteSetting visible={visible} hide={hide} onReload={getData} ID={ID} />
    </div>
  );
};

export default SettingScreen;
