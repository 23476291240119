import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
// import component 👇
import Drawer from "react-modern-drawer";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import {
  getListStudent,
} from "../Api/api";

//import styles 👇
import "react-modern-drawer/dist/index.css";
import { createPastEntries } from "../Api/api";
import * as dayjs from "dayjs";

const EditEntries = (props) => {
  const { isOpen, toggleDrawer, item, classes, students, reasons, onReload } =
    props;

  const [selectClass, setSelectClass] = useState();
  const [selectStudent, setSelectStudent] = useState();
  const [selectReason, setSelectReason] = useState();
  const [selectType, setSelectType] = useState();
  const [otherReason, setOtherReason] = useState("");
  const [occurAt, setOccurAt] = useState(dayjs())
  const [studentNew, setStudentNew] = useState(students)

  const handleChange = (newValue) => {
    setOccurAt(newValue);
  };

  const handlerChangeClass = (e) => {
    setSelectClass(e.target.value)
    getListStudent(1,e.target.value)
      .then((res) => {
        if (res?.results?.students) {
          const studenConerts =  res.results.students.map((item) => {
            return {
              id: item._id,
              fullName: item.fullName,
            };
          });
          setStudentNew(studenConerts);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const onSave = async () => {
    const params = {
      class: selectClass,
      student: selectStudent,
      reason: selectReason == "other" ? null : selectReason,
      type: selectType,
      other: otherReason,
      occurAt: occurAt,
    };

    try {
      const response = await createPastEntries(params);
      console.log("KIEM TRA RESPONSE", response);
      if (response.statusCode === 200) {
        toggleDrawer();
        onReload();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Drawer
      open={isOpen}
      onClose={toggleDrawer}
      direction="right"
      className="drawer"
      size={700}
    >
      <div className="drawer__header">
        <div>
          <div className="drawer__header__title">Update Entry</div>
          <div className="drawer__header__subtitle">
            Updated your student entry and necessary information from here
          </div>
        </div>
        <div className="drawer__header__close" onClick={toggleDrawer}>
          <IoMdClose className="icon" />
        </div>
      </div>
      <div className="drawer__body">
        <div className="card-sys">
          <div className="card-sys__item">
            <div className="title">Type</div>
            <div className="content">
              <div className="select">
                <select
                  onChange={(e) => setSelectType(e.target.value)}
                  name=""
                  id=""
                >
                  <option selected disabled hidden value="">
                    Select Type
                  </option>
                  <option value="late_coming">Late Coming</option>
                  <option value="early_dissmisal">Early Dismissal</option>
                </select>
              </div>
            </div>
          </div>
          <div className="card-sys__item">
            <div className="title">Class</div>
            <div className="content">
              <div className="select">
                <select
                  onChange={handlerChangeClass}
                  name=""
                  id=""
                >
                  <option selected disabled hidden value="">
                    Select Class
                  </option>
                  {classes.map((item, index) => (
                    <option value={item.id}>{item.class}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="card-sys__item">
            <div className="title">Student</div>
            <div className="content">
              <div className="select">
                <select
                  onChange={(e) => setSelectStudent(e.target.value)}
                  name=""
                  id=""
                >
                  <option selected disabled hidden value="">
                    Select Student
                  </option>
                  {studentNew.map((item, index) => (
                    <option value={item.id}>{item.fullName}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="card-sys__item">
            <div className="title">Date and Time Come/Left</div>
            <div className="content">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="Date and Time Come/Left"
                value={occurAt}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            </div>
          </div>
          <div className="card-sys__item">
            <div className="title">Reason</div>
            <div className="content">
              <div className="select">
                <select
                  onChange={(e) => setSelectReason(e.target.value)}
                  name=""
                  id=""
                >
                  <option selected disabled hidden value="">
                    Select Reason
                  </option>
                  {reasons.map((item, index) => (
                    <option value={item.id}>{item.reason}</option>
                  ))}
                  <option value={"other"}>Others</option>
                </select>
              </div>
            </div>
          </div>

          <div className="card-sys__item">
            <div className="title">Other Reason</div>
            <div className="content">
              <div className="input-container">
                <input
                  className="input"
                  disabled={selectReason !== "other"}
                  type="text"
                  value={otherReason}
                  onChange={(e) => setOtherReason(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="drawer__footer">
        <button className="btn btn-cancel" onClick={toggleDrawer}>
          Cancel
        </button>
        {item ? (
          <button
            onClick={() => {
              //   onUpdate();
            }}
            className="btn btn-update"
          >
            Update Entry
          </button>
        ) : (
          <button
            onClick={() => {
              onSave();
            }}
            className="btn btn-update"
          >
            Add Entry
          </button>
        )}
      </div>
    </Drawer>
  );
};

export default EditEntries;
