import React from "react";
import Rodal from "rodal";

import { BiTrash } from "react-icons/bi";

// include styles
import "rodal/lib/rodal.css";

import { deletePastEntry } from "../Api/api";

const DeleteEntries = (props) => {
  const { hide, visible, item, onReload } = props;

  const onDelete = async () => {
    const params = {
      id: item._id,
    };

    try {
      const response = await deletePastEntry(params);
      console.log("KIEM TRA RESPONSE", response);
      if (response.statusCode === 200) {
        hide();
        onReload();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Rodal
      width={600}
      height={300}
      visible={visible}
      showCloseButton={false}
      customStyles={{ borderRadius: "10px" }}
    >
      <div className="modal">
        <div className="modal__info">
          <div className="modal__info__icon">
            <BiTrash className="icon" />
          </div>
          <div className="modal__info__title">
            <span>Are You Sure! Want to Delete </span>
            <span className="color">{item && item?.fullName} </span>
            <span>Record?</span>
          </div>
          <div className="modal__info__subtitle">
            <span>
              Do you really want to delete these records? You can't view this in
              your list anymore if you delete!
            </span>
          </div>
        </div>
        <div className="modal__buttons">
          <button className="btn btn-cancel" onClick={hide}>
            Cancel
          </button>
          <button
            onClick={() => {
              onDelete();
            }}
            className="btn btn-delete"
          >
            Delete
          </button>
        </div>
      </div>
    </Rodal>
  );
};

export default DeleteEntries;
