import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import { HiOutlinePlusSm } from "react-icons/hi";
import { getListReason } from "../../components/Api/api";
import DeleteReason from "./DeleteReason";
import EditReason from "./EditReason";
import { ButtonGroup, Button } from "@mui/material"

const Reason = () => {
  const [reasons, setReasons] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [item, setItem] = useState(null);
  const [page, setPage] = useState(1)
  const [hasNext, setHasNext] = useState(false)

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const show = () => {
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };

  const getReason = () => {
    getListReason()
      .then((res) => {
        console.log(res);
        if (res?.results?.students) {
          setReasons(res.results.students);
        }
        setHasNext(res.results.hasNext)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getReason();
  }, []);

  return (
    <div className="admin__main__body">
      <div className="admin__main__body__title">
        <span>Reason</span>
      </div>
      <div className={classNames("admin__main__body__search", "classes")}>
        <div
          onClick={() => {
            setItem(null);
            toggleDrawer(); 
          }}
          className="button"
        >
          <HiOutlinePlusSm className="icon" />
          <span>Add Reason</span>
        </div>
      </div>
      <div className="admin__main__body__table">
        <div className="table">
          <div className="table__header">
            <div className="table__header__item">id</div>
            <div className="table__header__item">Name</div>
            <div className="table__header__item">Type</div>
            <div className="table__header__item flex-action">Actions</div>
          </div>
          {reasons.map((item, index) => (
            <div className="table__body">
              <div className="table__body__item">{index + 1}</div>
              <div className="table__body__item">{item.name}</div>
              <div className="table__body__item">{item.type == "late_coming" ? "Late Coming" : "Early Dissmisal"}</div>
              <div className="table__body__item flex-action">
                <BiEdit
                  onClick={() => {
                    toggleDrawer();
                    setItem(item);
                  }}
                  className="edit"
                />
                <BiTrash
                  onClick={() => {
                    show();
                    setItem(item);
                  }}
                  className="delete"
                />
              </div>
            </div>
          ))}
        </div>
        <div className="table_header" style={{display: 'flex', justifyContent: "flex-end", marginTop: '20px'}}>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button disabled={page <= 1} onClick={()=>{ setPage(page -1); getReason(page -1 ) }}>Pre</Button>
              <Button disabled={!hasNext} onClick={()=>{  setPage(page +1);  getReason(page +1) }}>Next</Button>
            </ButtonGroup>
        </div>
      </div>
      <EditReason
        isOpen={isOpen}
        toggleDrawer={toggleDrawer}
        item={item}
        onReload={()=> getReason(page)}
      />

      <DeleteReason
        visible={visible}
        hide={hide}
        item={item}
        onReload={()=> getReason(page)}
      />
    </div>
  );
};

export default Reason;
