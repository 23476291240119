import React from "react";
import { Button, Dialog, DialogActions, DialogContent,DialogContentText, DialogTitle } from "@mui/material"

const ConfirmDialog = (props) => {
  const { title, children, confirmOpen, setConfirmOpen, onConfirm } = props;
  return (
    <Dialog
          open={confirmOpen}
          onClose={()=>setConfirmOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {children}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>setConfirmOpen(false)}>Cancel</Button>
            <Button onClick={()=>{setConfirmOpen(false); onConfirm()}} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
  );
};

export default ConfirmDialog;
