import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { HiOutlineMinusSm } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
// import component 👇
import Drawer from "react-modern-drawer";

//import styles 👇
import "react-modern-drawer/dist/index.css";
import { updateTerm } from "../Api/api";

const AddSetting = (props) => {
  const { isOpen, toggleDrawer, onReload, listData, ID } = props;

  const [name, setName] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [terms, setTerms] = useState(listData);
  const [isAdd, setIsAdd] = useState([]);
  const termData = listData

  const handleAddTerm = (index) => {
    const params = {
      name: "New term",
      from: moment(),
      to: moment(),
    };

    const newTerm = [...terms];
    termData.push(params)
    newTerm.push(params);
    setTerms(newTerm);
    setName();
    setFromDate();
    setToDate();
  };

  const updateName = (value,index) =>{
    termData[index].name = value
  }

  const updateDate = (value,field,index) =>{
    termData[index][field] = moment(value)
  }

  const handleRemoveTerm = (index) => {
    const newTerms = [...terms];
    newTerms.splice(index, 1);
    termData.splice(index,1)
    setTerms(newTerms);
  };

  useEffect(() => {
    setTerms(listData);
    setIsAdd([]);
  }, [isOpen]);

  const onSave = async () => {
    const params = {
      id: ID,
      data: termData,
    };

    try {
      const response = await updateTerm(params);
      if (response.statusCode === 200) {
        onReload();
        toggleDrawer();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleIsAdd = (index) => {
    const newIsAdd = { ...isAdd };
    newIsAdd[index] = true;
    setIsAdd(newIsAdd);
  };

  const handleRemoveIsAdd = (index) => {
    const newIsAdd = { ...isAdd };
    newIsAdd[index] = false;
    setIsAdd(newIsAdd);
  };

  return (
    <Drawer
      open={isOpen}
      onClose={toggleDrawer}
      direction="right"
      className="drawer"
      size={700}
    >
      <div className="drawer__header">
        <div>
          <div className="drawer__header__title">Update Entry</div>
          <div className="drawer__header__subtitle">
            Updated your student entry and necessary information from here
          </div>
        </div>
        <div className="drawer__header__close" onClick={toggleDrawer}>
          <IoMdClose className="icon" />
        </div>
      </div>
      <div className="drawer__body">
        <div className="card-sys">
          {terms.length > 0 &&
            terms.map((item, index) => (
              <>
                <div className="card-sys__item">
                  <div className="title">Name</div>
                  <div className="content">
                    <div className="input-container">
                      <input
                        className="input"
                        type="text"
                        defaultValue={item.name}
                        onChange={(e) => updateName(e.target.value,index)}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-sys__item">
                  <div className="title">From Date</div>
                  <div className="content">
                    <div className="input-container">
                      <input
                        className="input"
                        type="date"
                        defaultValue={moment(item.from).format("YYYY-MM-DD")}
                        onChange={(e) => updateDate(e.target.value,"from",index)}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-sys__item">
                  <div className="title">To Date</div>
                  <div className="content">
                    <div className="input-container">
                      <input
                        className="input"
                        type="date"
                        defaultValue={moment(item.to).format("YYYY-MM-DD")}
                        onChange={(e) => updateDate(e.target.value,"to",index)}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-sys__button">
                    <div
                      onClick={() => {
                        handleRemoveTerm(index);
                      }}
                      className="button-container"
                    >
                      <span>Delete</span>
                    </div>
                  </div>
                <div className="line" />
              </>
            ))}
          <div className="card-sys__button">
            <div
              onClick={() => {
                handleAddTerm();
              }}
              className="button-container"
            >
              <span>Add</span>
            </div>
          </div>
        </div>
      </div>
      <div className="drawer__footer">
        <button className="btn btn-cancel" onClick={toggleDrawer}>
          Cancel
        </button>
        <button
          disabled={terms.length === 0}
          onClick={() => {
            onSave();
          }}
          className={classNames(
            "btn",
            terms.length === 0 ? "btn-disabled" : "btn-update"
          )}
        >
          Update Term
        </button>
      </div>
    </Drawer>
  );
};

export default AddSetting;
