import React, { useState } from "react";
import Header from "../components/Header/Header";
import Nav from "../components/Navbar/Nav";
import Student from "../components/Student/Student";
import Class from "../components/Class/Class";
import Users from "../components/Users/Users";
import InfoStudent from "../components/Student/InfoStudent";
import PastEntries from "../components/PastEntries/PastEntries";
import SettingScreen from "../components/Setting/Setting";

import Reason from "../components/Reason/Reason";
const Home = () => {
  const [active, setActive] = useState("Past Entries");

  return (
    <div className="admin">
      <Nav active={active} setActive={setActive} />
      <div className="admin__main">
        <Header />

        {/* {active === "Student" && (
          <Routes>
            <Route path="/" element={<Student />}></Route>
            <Route path="/InfoStudent" element={<InfoStudent />}></Route>
          </Routes>
        )} */}

        {active === "Student" && <Student />}
        {active === "Class" && <Class />}
        {active === "User" && <Users />}
        {active === "InfoStudent" && <InfoStudent />}
        {active === "Past Entries" && <PastEntries />}
        {active === "Setting" && <SettingScreen />}
        {active === "Reason" && <Reason />}
      </div>
    </div>
  );
};

export default Home;
