import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "../pages/Login";

import Home from "../pages/Home";
import { getCookieToken, getListUser } from "../components/Api/api";
// import Register from "../pages/Register";

const Routers = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (getCookieToken !== null) {
      getListUser().then((res)=>{
        if(res.statusCode == 401){
          localStorage.removeItem("token")
          navigate("/login");
        } else {
          navigate("/");
        }
      }).catch((err)=>{
        // localStorage.removeItem("token")
        // navigate("/login");
      })
      
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/" element={<Home />}></Route>
    </Routes>
  );
};

export default Routers;
