import React from 'react'
import Routes from "../../routes/Routers";


const Layout = () => {
  return (
    <div>
      <Routes />
    </div>
  )
}

export default Layout